import { useState } from 'react';
import { Modal, Button, Container, Col, Form } from "react-bootstrap";

const Searchbar = (props) => {

  //let s = new Date(new Date().getFullYear(), 0, 1).toISOString().slice(0,10);
  //console.log(s);

  const [dokPartner, setDokPartner] = useState('');
  const [dokOib, setDokOib] = useState('');
  const [dokStatus, setDokStatus] = useState('');
  const [oddatuma, setOdDatumaStatus] = useState(new Date(new Date().getFullYear(), 0, 2).toISOString().slice(0,10));
  const [dodatuma, setDoDatumaStatus] = useState(new Date().toISOString().slice(0,10));

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const handlePartnerChange = event => {
    setDokPartner(event.target.value)
  };

  const handleOibChange = event => {
    setDokOib(event.target.value)
  };

  const handleStatusChange = event => {
    setDokStatus(event.target.value)
  };

  const handleOdDatumaChange = event => {
    setOdDatumaStatus(event.target.value)
  };

  const handleDoDatumaChange = event => {
    setDoDatumaStatus(event.target.value)
  };

  const handleSubmit = event => {
    event.preventDefault();

    // ovdje provjeravam da li su podaci ok, ako nisu ok stavi Modal dialog
    // provjere, datumi moraju imati dužinu od 10 znakova
    var parts = oddatuma.split('-');
    var date_from = new Date(parts[0], parts[1] - 1, parts[2]);
    var parts1 = dodatuma.split('-');
    var date_to = new Date(parts1[0], parts1[1] - 1, parts[2]);

    if ( !(oddatuma.length === 10) || !(dodatuma.length === 10) || (oddatuma > dodatuma) || isNaN(date_from) || isNaN(date_to) ) {
      setShowModal(true);
    }
    else
    {
      props.onChange({Partner: dokPartner, Oib: dokOib, Status: dokStatus, OdDatuma: oddatuma, DoDatuma: dodatuma});
    }
  };

  return (
  <Container>
    <Form onSubmit={handleSubmit} >

    <br />

    <>
     <Modal  animation={false} show={showModal} onHide={handleClose}>
       <Modal.Header closeButton>
         <Modal.Title>Upozorenje!</Modal.Title>
       </Modal.Header>
       <Modal.Body>Greška u unosu datuma!</Modal.Body>
       <Modal.Footer>
         <Button variant="secondary" onClick={handleClose}>
           Close
         </Button>
       </Modal.Footer>
     </Modal>
   </>

    <br />

    <Form.Row>
      <Col>
        <Form.Label>Od datuma</Form.Label>
        <Form.Control type="date" name="oddatuma" placeholder="Izaberi datum" value={oddatuma} onChange={handleOdDatumaChange}/>
      </Col>
      <Col>
        <Form.Label>Do datuma</Form.Label>
        <Form.Control type="date" name="dodatuma" placeholder="Izaberi datum" value={dodatuma} onChange={handleDoDatumaChange}/>
      </Col>
    </Form.Row>

    <br />

    <Form.Row>
      <Col>
        <Form.Label>Naziv partnera</Form.Label>
        <Form.Control
          placeholder="Naziv partnera"
          onChange={handlePartnerChange}
          value={dokPartner}        />

      </Col>
      <Col>
        <Form.Label>OIB</Form.Label>
        <Form.Control
          placeholder="OIB"
          onChange={handleOibChange}
          value={dokOib}
        />
      </Col>
      <Col>
        <Form.Label>Status dokumenta</Form.Label>
        <Form.Control as="select" custom onChange={handleStatusChange} value={dokStatus}>
          <option></option>
          <option>neobradjen</option>
          <option>email</option>
          <option>preuzet</option>
          <option>preuzet (n)</option>
        </Form.Control>
      </Col>
    </Form.Row>

    <br />

    <Form.Row>
      <Button variant="primary" type="submit">
        Traži
      </Button>
    </Form.Row>

    <br />

  </Form>


</Container>
)

}

export default Searchbar
