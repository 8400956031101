import { BrowserRouter, Switch, Route} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './App.css';
import Eracuni from './containers/Eracuni/Eracuni';
import Eadmin from './components/Eadmin/Eadmin';
import Login from './components/Login/Login';
import useToken from './useToken';

const App = () => {

    const { token, setToken } = useToken();

    if(!token) {
      return (
        <Login
           setToken={ setToken }
        />
      )
    }


   return (

     <BrowserRouter>

        <Switch>
        {/*
          <Route path="/"> <Eracuni tok={token}/> </Route>
        */}

          <Route exact path="/" render={(props) => (<Eracuni {...props} userToken={token} />)} />
          <Route exact path="/admin" render={(props) => (<Eadmin {...props} userToken={token} />)} />          

        </Switch>
     </BrowserRouter>
   )
};

export default App;
