import { useEffect, useState } from 'react';
import { Modal, Button, Container, Col, Form, ButtonGroup, ToggleButton, ToggleButtonGroup } from "react-bootstrap";


const Searchbar2 = (props) => {

  //const listaPartnera = [];
  const [radioValue, setRadioValue] = useState('1');
  const radios = [
    { name: 'Izabrani', valueIzabrano: '1', checked: 'true' },
    { name: 'Svi', valueIzabrano: '2', checked: 'false' }
  ];

  const [listaPartnera, setListaPartnera] = useState([]);

  const [dokOib, setDokOib] = useState('');
  const [oddatuma, setOdDatumaStatus] = useState('');//useState(new Date(new Date().getFullYear(), 0, 1));
  const [dodatuma, setDoDatumaStatus] = useState('');

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const handleOdDatumaChange = event => {
    setOdDatumaStatus(event.target.value)
  };

  const handleDoDatumaChange = event => {
    setDoDatumaStatus(event.target.value)
  };

  const handleSubmit = event => {
    event.preventDefault();

    // ovdje provjeravam da li su podaci ok, ako nisu ok stavi Modal dialog
    // provjere, datumi moraju imati dužinu od 10 znakova
    var parts = oddatuma.split('-');
    var date_from = new Date(parts[0], parts[1] - 1, parts[2]);
    var parts1 = dodatuma.split('-');
    var date_to = new Date(parts1[0], parts1[1] - 1, parts[2]);

    if ( !(oddatuma.length === 10) || !(dodatuma.length === 10) || (oddatuma > dodatuma) || isNaN(date_from) || isNaN(date_to) ) {
      setShowModal(true);
    }
    else
    {
       props.onChange({Oib: dokOib, OdDatuma: oddatuma, DoDatuma: dodatuma, tipPrikaza: radioValue});
    }

  };

  useEffect(() => {
    console.log('useEffect');
    fetch('https://eracuni.pakom.hr/preuzmi_partnere.php')
          .then(response => response.json())
          .then(responseData =>
            {

              //console.log(responseData);
              const lp = [];
              responseData.forEach ( obj1 => {
                lp.push({
                  oib: obj1.oib,
                  partner: obj1.naziv_firme
                })
              });
              setListaPartnera(lp);
              setDokOib(lp[0].oib)

            })

      },[]);

  return (
  <Container>
    <Form onSubmit={handleSubmit} >

    <br />

    <>
     <Modal animation={false}  show={showModal} onHide={handleClose}>
       <Modal.Header closeButton>
         <Modal.Title>Upozorenje!</Modal.Title>
       </Modal.Header>
       <Modal.Body>Greška u unosu datuma!</Modal.Body>
       <Modal.Footer>
         <Button variant="secondary" onClick={handleClose}>
           Close
         </Button>
       </Modal.Footer>
     </Modal>
   </>

    <Form.Group controlId="formOibSelect">
       <Form.Label>Izaberi partnera</Form.Label>
       <Form.Control
         as="select"
         onChange={e => {setDokOib(e.target.value)}}
       >

       {
         listaPartnera.map((m, i) => {
             return <option key={i} value={m.oib}>{m.oib} - {m.partner}</option>
         })
       }

       </Form.Control>
    </Form.Group>


    <Form.Row>
      <Col>
        <Form.Label>OIB</Form.Label>
        <Form.Control
          placeholder="OIB"
          value={dokOib}
          maxLength="11"
        />
      </Col>
      <Col>
        <Form.Label>Od datuma</Form.Label>
        <Form.Control type="date" name="oddatuma" placeholder="Izaberi datum" value={oddatuma} onChange={handleOdDatumaChange}/>
      </Col>
      <Col>
        <Form.Label>Do datuma</Form.Label>
        <Form.Control type="date" name="dodatuma" placeholder="Izaberi datum" value={dodatuma} onChange={handleDoDatumaChange}/>
      </Col>

    </Form.Row>

    <br />

    <Form.Row>
      <Col>
        <Button variant="primary" type="submit">
          Traži
        </Button>
      </Col>

      <Col>
        <ToggleButtonGroup name="radio" value={radioValue} style={{float : 'right'}}>
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant={idx % 2 ? 'outline-info' : 'outline-info'}
              name="radio"
              value={radio.valueIzabrano}
              onChange={(e) => setRadioValue(e.currentTarget.value)}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Col>

    </Form.Row>



    <br />

  </Form>


</Container>
)

}

export default Searchbar2
