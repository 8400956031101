import { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./Login.css";

function ifObjectIsEmpty(object){
   var isEmpty=true;
   if(JSON.stringify(object)===JSON.stringify({})){
     isEmpty = true;
   }
   else{
     isEmpty = false;
   }
   return isEmpty;
}

async function loginUser(username, password) {
  return fetch('https://eracuni.pakom.hr/auth.php?username='+username+'&password='+password)
  .then(data => data.json())
 }

const Login = ( props ) => {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async e => {
      e.preventDefault();

      const token = await loginUser(
        username,
        password
      );

      if (ifObjectIsEmpty(token)) {
        alert("Neispravna šifra!");
          }
      else {
        props.setToken(token);
      }
    }

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  return (
    <div className="Login">
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>Username</Form.Label>
          <Form.Control
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button block size="lg" type="submit" disabled={!validateForm()}>
          Login
        </Button>
      </Form>
    </div>
  );
}

export default Login
