import { useState } from 'react';
import Searchbar from '../../components/Searchbar/Searchbar'
import Navigation from '../../components/Navigation/Navigation'
import Tabledata from '../../components/Tabledata/Tabledata'
import { Container } from "react-bootstrap";

const Eracuni = (props) => {

  const [filterData, setFilterData] = useState();

  const updateFilterData = (input) => {
     setFilterData(input);
  }

  function logout() {
    sessionStorage.clear();
    window.location.href = '/';
  }

  return (
      <div>
        <Navigation
           userToken = { props.userToken }
        />
        <Searchbar
           onChange={ updateFilterData }
        />
        <Tabledata
           filterTable = { filterData }
           userToken = { props.userToken }
        />
       <Container>
       <div><button onClick={logout}>Odjava</button></div>
       </Container>
      </div>
  );
}

export default Eracuni;
