import {useRef, useEffect, useState} from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import axios from 'axios';
import Badge from 'react-bootstrap/Badge';
import { Container } from "react-bootstrap";
import { FaTimes, FaEnvelopeSquare, FaProductHunt, FaTrash, FaDownload, FaEnvelope } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useReactToPrint } from 'react-to-print';

const Tabledata = (props) => {

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleClickDelete = value => () => {

    let podaci = {
      uid1: value.korisnik_api,
      uid2: value.dok_api
    }

    let config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    axios.post('https://eracuni.pakom.hr/brisizapis_demo.php', JSON.stringify(podaci), config)
        .then(result => {
            //console.log(result.data);
        })

    // tu bih morao promjeni status podataka za prikaz
    let newDokData = [...dokData];
    newDokData = newDokData.map((d) => {
          if (d.dok_api === value.dok_api) {
            return {
              ...d,
              dok_status: 'izbrisano'
            };
          }
          return d;
    });
    setDokData(newDokData);

  };

  const handleClickPreuzet = value => () => {

    let podaci = {
      uid1: value.korisnik_api,
      uid2: value.dok_api
    }

    let config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    axios.post('https://eracuni.pakom.hr/oznakapreuzet.php', JSON.stringify(podaci), config)
        .then(result => {
            //console.log(result.data);
        })

    // tu bih morao promjeni status podataka za prikaz
    let newDokData = [...dokData];
    newDokData = newDokData.map((d) => {
          if (d.dok_api === value.dok_api) {
            return {
              ...d,
              dok_status: 'preuzet'
            };
          }
          return d;
    });
    setDokData(newDokData);

  };

  const handleClickPreuzmi = value => () => {
    window.open("https://eracuni.pakom.hr/preuzmi.php?dok="+value.dok_api+"&lokalno=da");
  };

  //const handleClickPosaljiMail = value => () => {
  const handleClickPosaljiMail = value => () => {

    let podaci = {
      uid1: value.korisnik_api,
      uid2: value.dok_api,
      mail_stranke: value.dok_mail
    }

    let config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }


    axios.post('https://eracuni.pakom.hr/posaljimail.php', JSON.stringify(podaci), config)
        .then(result => {
            //console.log(result.data);
        })

    // tu bih morao promjeni status podataka za prikaz
    let newDokData = [...dokData];
    newDokData = newDokData.map((d) => {
          if (d.dok_api === value.dok_api) {
            return {
              ...d,
              dok_status: 'poslan mail'
            };
          }
          return d;
    });
    setDokData(newDokData);
  };

  const handleClickBrisem = value => () => {

    confirmAlert({
      title: 'Upozorenje!',
      message: 'Da li želite izbrisati zapis?',
      buttons: [
        {
          label: 'Da',
          onClick: handleClickDelete(value)
        },
        {
          label: 'Ne'
        }

      ]
    });

  };


  function docFormatter(cell, row, rowIndex) {

    if (row.dok_mail === undefined) {return ( <div> </div> );}

    const words = cell.split('-');
    return (
      <div>
      {words[0]}
      <br />
      {words[1]}
      </div>
    );
  }

  function mailFormatter(cell, row, rowIndex) {

    if (cell === undefined) {return ( <div> </div> );}

    const mails = cell.split(',');

    let mailLista = [];

    mails.forEach( m => {

      mailLista.push({
        dok_id: row.dok_id,
        dok_api: row.dok_api,
        dok_zaprimljen: '',
        dok_poslan: '',
        dok_status: '',
        dok_mail: m,
        dok_opis: '',
        dok_opis_greske: ''
      })

      dokData2.forEach( dd => {

        if ((dd.dok_api === row.dok_api) && (m === dd.dok_mail))
        {
          /*
          ne radi jer nije iterable, nedamise sada tražiti

          mailLista.push(
            ...dd
          );
          */

          // izbriši zadnjeg
          mailLista.pop();
          // i stavi novog
          mailLista.push({
            dok_id: dd.dok_id,
            dok_api: dd.dok_api,
            dok_zaprimljen: dd.dok_zaprimljen,
            dok_poslan: dd.dok_poslan,
            dok_status: dd.dok_status,
            dok_mail: dd.dok_mail,
            dok_opis: dd.dok_opis,
            dok_opis_greske: dd.dok_opis_greske
          })

        }
      });
    });

    //console.log(mailLista);

    return (
        <div>
        {
          mailLista.map((m, i) => {
              if (m.dok_opis_greske === '') {
                return <p key={i}><FaEnvelopeSquare style={{color:'green'}}/> {m.dok_mail}</p>
              }
              else {
                return <p key={i} title={m.dok_opis_greske}><FaTimes style={{color:'red'}}/> {m.dok_mail}</p>
              }
            })
        }
        </div>

      );
    }

  const columns = [
    {
      dataField: 'korisnik_api',
      text: 'KorisnikApi',
      hidden: true
    },
    {
      dataField: 'dok_id',
      text: 'Id',
      hidden: true
    },
    {
      dataField: 'dok_primatelj',
      text: 'Primatelj',
      headerStyle: { overflowWrap: 'break-word'},
      formatter: docFormatter
    },
    {
      dataField: 'dok_zaprimljen',
      style: { fontSize: '12px' },
      text: 'Poslano',
      headerStyle: { width: '120px', textAlign: 'center',  overflowWrap: 'break-word'}
    },
    {
      dataField: 'dok_poslan',
      style: { fontSize: '12px' },
      text: 'Preuzeto',
      headerStyle: { width: '120px', textAlign: 'center',  overflowWrap: 'break-word'}
    },
    {
      dataField: 'dok_mail',
      text: 'Email primatelja',
      style: { overflowWrap: 'break-word', fontSize: '12px' },
      headerStyle: { width: '240px', textAlign: 'center',  overflowWrap: 'break-word'},
      formatter: mailFormatter
    },
    {
      dataField: 'df4',
      isDummyField: true,
      text: 'Status',
      formatter: (cellContent, row) => {
        switch(row.dok_status) {
          case 'email':
            return (
              <h6>
                <Badge variant="primary">email</Badge>
              </h6>
            );

          case 'email PDF':
            return (
              <h6>
                <Badge style={{backgroundColor:'pink'}}>email PDF</Badge>
              </h6>
            );

          case 'preuzet':
            return (
              <h6>
                <Badge variant="success">preuzet</Badge>
              </h6>
            );

          case 'preuzet (n)':
            return (
              <h6>
                <Badge variant="info">preuzet (n)</Badge>
              </h6>
            );

          case 'neobradjen':
            return (
              <h6>
                <Badge variant="danger">neobrađen</Badge>
              </h6>
            );

          default:
            return (
              <h6>
                <Badge variant="warning">{row.dok_status}</Badge>
              </h6>
            );

          }
      }
    },
    {
      dataField: 'dok_status',
      text: 'Status',
      hidden: true,
      headerStyle: { width: '120px', textAlign: 'center' }
    },
    {
      dataField: 'dok_broj',
      text: 'Broj dok.',
      headerStyle: { width: '100px', textAlign: 'center' }
    },
    {
      dataField: 'df1',
      isDummyField: true,
      text: '',
      headerStyle: { width: '40px', textAlign: 'center' },
      formatter: (cellContent, row) => {
              return (
                <FaDownload style={{cursor: 'pointer', color:'black'}} onClick={handleClickPreuzmi(row)} title="Preuzmi dokument" />
              );
          }
    },
    {
      dataField: 'df2',
      isDummyField: true,
      text: '',
      headerStyle: { width: '40px', textAlign: 'center' },
      formatter: (cellContent, row) => {
        return (
          <FaEnvelope style={{cursor: 'pointer', color:'blue'}} onClick={handleClickPosaljiMail(row)} title="Ponovo pošalji mail" />
          );
      }
    },
    {
      dataField: 'df3',
      isDummyField: true,
      text: '',
      hidden: true,
      headerStyle: { width: '40px', textAlign: 'center' },
      formatter: (cellContent, row) => {
        return (
          <FaTrash style={{cursor: 'pointer', color:'red'}} onClick={handleClickBrisem(row)} title="Brisanje zapisa" />
          );
      }
    },
    {
      dataField: 'df3',
      isDummyField: true,
      text: '',
      hidden: false,
      headerStyle: { width: '40px', textAlign: 'center' },
      formatter: (cellContent, row) => {
        return (
          <FaProductHunt style={{cursor: 'pointer', color:'green'}} onClick={handleClickPreuzet(row)} title="Označi da je preuzet" />
          );
      }
    }





  ];

  const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
    <div>
      <PaginationProvider
        pagination={
          paginationFactory({
            custom: true,
            page,
            sizePerPage,
            totalSize
          })
        }
      >
        {
          ({
            paginationProps,
            paginationTableProps
          }) => (
            <div>
              <div>
                {/*
                <p>Current Page: { paginationProps.page }</p>
                <p>Current SizePerPage: { paginationProps.sizePerPage }</p>
                */}
              </div>
              <div>
                <PaginationListStandalone
                  { ...paginationProps }
                />
              </div>
              <BootstrapTable
                remote
                keyField="dok_id"
                data={ data }
                columns={ columns }
                onTableChange={ onTableChange }
                { ...paginationTableProps }
              />
            </div>
          )
        }
      </PaginationProvider>
    </div>
  );

  const [dokData, setDokData] = useState([]);
  const [dokData2, setDokData2] = useState([]);
  const [stranica, setStranica] = useState();
  const [broj_redova, setBrojRedova] = useState(0);

  //console.log('Tabledata  : ' + JSON.stringify(props.userToken.api_key));

  useEffect(() => {



    let a = '';
    if (props.filterTable) {
      a = 'status='+props.filterTable.Status+'&naziv='+props.filterTable.Partner+'&oib='+props.filterTable.Oib+'&oddatuma='+props.filterTable.OdDatuma+'&dodatuma='+props.filterTable.DoDatuma;
    }
    else {
      a = 'status=';
    }

    const apiKey = props.userToken.api_key;

    //console.log('PHP upit : ' + 'https://eracuni.pakom.hr/preuzmi.php?kor='+apiKey+'&brstr=0&brred=8&'+a);

    fetch('https://eracuni.pakom.hr/preuzmi.php?kor='+apiKey+'&brstr=0&brred=8&'+a)
          .then(response => response.json())
          .then(responseData =>
            {
              const dokData = [];


              //console.log('lista : ' + responseData['lista']);
              //console.log('lista1 : ' + responseData['lista1']);
              //console.log('lista2 : ' + responseData['lista2']);


              // lista
              const a1 = JSON.parse(responseData['lista']);
              a1.forEach ( obj1 => {
                dokData.push({
                  korisnik_api: obj1.api_key_korisnik,
                  dok_id: obj1.id,
                  dok_api: obj1.api_key_dokumenta,
                  dok_zaprimljen: obj1.vrijeme_primitka,
                  dok_poslan: obj1.vrijeme_preuzimanja,
                  dok_status: obj1.status,
                  dok_primatelj: obj1.primatelj_naziv+String.fromCharCode(10)+'-'+obj1.primatelj_oib,
                  dok_mail: obj1.primatelj_email,
                  dok_broj: obj1.broj_dokumenta
                })
              });

              // lista 1
              const a2 = JSON.parse(responseData['lista1']);
              let broj_redova = a2[0].broj_redova;

              //console.log(broj_redova);

              // lista 2
              const dokData2 = [];
              const a3 = JSON.parse(responseData['lista2']);
              a3.forEach ( obj2 => {
                if (typeof obj2.rezultat == 'undefined') {
                  dokData2.push({
                    dok_id: obj2.id,
                    dok_api: obj2.api_key_dokumenta,
                    dok_zaprimljen: obj2.vrijeme_slanja,
                    dok_poslan: obj2.vrijeme_statusa,
                    dok_status: obj2.status,
                    dok_mail: obj2.mail,
                    dok_opis: obj2.opis,
                    dok_opis_greske: obj2.opis_greske
                  })
                }
              });


              setDokData(dokData);
              setDokData2(dokData2);
              setStranica(1);
              setBrojRedova(broj_redova);
            })

      },[props.filterTable]);

    const  handleTableChange = (type, { page, sizePerPage }) => {

         const currentIndex = (page - 1) * sizePerPage;
         const stranicaBaza = page - 1;

         let a = '';
         if (props.filterTable) {
           a = 'status='+props.filterTable.Status+'&naziv='+props.filterTable.Partner+'&oib='+props.filterTable.Oib+'&oddatuma='+props.filterTable.OdDatuma+'&dodatuma='+props.filterTable.DoDatuma;
         }
         else {
           a = 'status=';
         }

         const apiKey = props.userToken.api_key;

         fetch('https://eracuni.pakom.hr/preuzmi.php?kor='+apiKey+'&brstr='+stranicaBaza+'&brred=8&'+a)
               .then(response => response.json())
               .then(responseData =>
                   {
                   const dokData = [];
                   // lista
                   const a1 = JSON.parse(responseData['lista']);
                   a1.forEach ( obj1 => {
                     dokData.push({
                       korisnik_api: obj1.api_key_korisnik,
                       dok_id: obj1.id,
                       dok_api: obj1.api_key_dokumenta,
                       dok_zaprimljen: obj1.vrijeme_primitka,
                       dok_poslan: obj1.vrijeme_preuzimanja,
                       dok_status: obj1.status,
                       dok_primatelj: obj1.primatelj_naziv+String.fromCharCode(10)+'-'+obj1.primatelj_oib,
                       dok_mail: obj1.primatelj_email,
                       dok_broj: obj1.broj_dokumenta
                     })
                   });

                   // lista 2
                   const dokData2 = [];
                   const a3 = JSON.parse(responseData['lista2']);
                   a3.forEach ( obj2 => {
                     if (typeof obj2.rezultat == 'undefined') {
                       dokData2.push({
                         dok_id: obj2.id,
                         dok_api: obj2.api_key_dokumenta,
                         dok_zaprimljen: obj2.vrijeme_slanja,
                         dok_poslan: obj2.vrijeme_statusa,
                         dok_status: obj2.status,
                         dok_mail: obj2.mail,
                         dok_opis: obj2.opis,
                         dok_opis_greske: obj2.opis_greske
                       })
                     }
                   });

                   setDokData(dokData);
                   setDokData2(dokData2);
                   setStranica(page);

                 })
       }


  return (
    <Container ref={componentRef}>

      <RemotePagination
        data={ dokData }
        page={ stranica }
        sizePerPage={ 8 }
        totalSize={ broj_redova }
        onTableChange={ handleTableChange }
      />

      <button type="button" className="bg-gray-500 border border-gray-500 p-2 mb-4" style={{float : 'right'}} onClick={handlePrint}>Ispis</button>
    </Container>
  )
}

export default Tabledata
