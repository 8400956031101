import { useState } from 'react';
import Searchbar2 from '../../components/Searchbar2/Searchbar2'
import Navigation from '../../components/Navigation/Navigation'
import Tabledata2 from '../../components/Tabledata2/Tabledata2'
import { Container } from "react-bootstrap";

const Eadmin = (props) => {

  const [filterData, setFilterData] = useState();

  const updateFilterData = (input) => {
     setFilterData(input);
  }

  function logout() {
    sessionStorage.clear();
    window.location.href = '/';
  }

  if (props.userToken.tip.toString() === '0')
  {
    return (
      <div>
        <Navigation
          userToken = { props.userToken }
          />
          <Container>
          <div></div>
          </Container>
          </div>
        );
  }
  else {
    return (
      <div>
        <Navigation
           userToken = { props.userToken }
        />
        <Searchbar2
           onChange={ updateFilterData }
        />
        <Tabledata2
           filterTable = { filterData }
           userToken = { props.userToken }
        />
       <Container>
       <div><button onClick={logout} className="bg-gray-500 border border-gray-500 p-2 mb-4" style={{float : 'left'}} >Odjava</button></div>
       </Container>
      </div>
    );
  }
}

export default Eadmin;
