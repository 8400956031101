import {useRef, useEffect, useState} from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import { useReactToPrint } from 'react-to-print';
import { Container } from 'react-bootstrap';

const columns = [
  {
    dataField: 'oib',
    text: 'OIB',
    hidden: false,
    headerStyle: { fontSize: '12px'}
  },
  {
    dataField: 'naziv',
    text: 'Naziv',
    headerStyle: { overflowWrap: 'break-word', fontSize: '12px'}
  },
  {
    dataField: 'od_datuma',
    style: { fontSize: '12px' },
    text: 'Od datuma',
    headerStyle: { width: '100px', textAlign: 'center',  overflowWrap: 'break-word', fontSize: '12px'}
  },
  {
    dataField: 'do_datuma',
    style: { fontSize: '12px' },
    text: 'Do datuma',
    headerStyle: { width: '100px', textAlign: 'center',  overflowWrap: 'break-word', fontSize: '12px'}
  },
  {
    dataField: 'kolicina',
    text: 'Ukupno',
    style: { fontSize: '12px', textAlign: 'center'},
    headerStyle: { width: '80px', textAlign: 'center',  overflowWrap: 'break-word', fontSize: '12px'}
  },
  {
    dataField: 'preuzeto',
    text: 'Preuzeto',
    style: { fontSize: '12px', textAlign: 'center' },
    headerStyle: { width: '80px', textAlign: 'center',  overflowWrap: 'break-word', fontSize: '12px'}
  },
  {
    dataField: 'nije_preuzeto',
    text: 'Nije preuzeto',
    style: { fontSize: '12px', textAlign: 'center' },
    headerStyle: { width: '80px', textAlign: 'center',  overflowWrap: 'break-word', fontSize: '12px'}
  },
  {
    dataField: 'ostalo',
    text: 'Ostalo',
    style: { fontSize: '12px', textAlign: 'center' },
    headerStyle: { width: '80px', textAlign: 'center',  overflowWrap: 'break-word', fontSize: '12px'}
  }

];


const Tabledata2 = (props) => {

  const [dokData, setDokData] = useState([]);

   const componentRef = useRef();
   const handlePrint = useReactToPrint({
     content: () => componentRef.current,
   });

  useEffect(() => {
    let a = '';
    if (props.filterTable) {
      a = 'oddatuma='+props.filterTable.OdDatuma+'&dodatuma='+props.filterTable.DoDatuma+'&oib='+props.filterTable.Oib+'&tipprikaza='+props.filterTable.tipPrikaza;
    }
    else {
      a = 'status=';
    }

    const apiTip = props.userToken.tip;

    //console.log('UPIT : http://eracuni.pakom.hr/preuzmi_admin.php?apiTip='+apiTip+'&tipUpita=1&'+a);
    //console.log(props.filterTable);

    fetch('https://eracuni.pakom.hr/preuzmi_admin.php?apiTip='+apiTip+'&tipUpita=1&'+a)
          .then(response => response.json())
          .then(responseData =>
            {
              //console.log(responseData);

              const dokData = [];

              /* ovo je staro, kada je samo jedan
              if (responseData[0].greska === '') {

                  dokData.push({
                    oib: responseData[0].oib,
                    naziv: responseData[0].naziv_firme,
                    od_datuma: responseData[0].od_datuma,
                    do_datuma: responseData[0].do_datuma,
                    kolicina: responseData[0].kolicina,
                    preuzeto: responseData[0].ukupno_preuzeto,
                    nije_preuzeto: responseData[0].ukupno_poslano,
                    ostalo: responseData[0].ukupno_ostalo
                  });

              }
              */

              // lista
              const a11 = JSON.stringify(responseData['lista']);
              const a1 = JSON.parse(a11);

              a1.forEach ( obj1 => {
                dokData.push({
                  oib: obj1.oib,
                  naziv: obj1.naziv_firme,
                  od_datuma: obj1.od_datuma,
                  do_datuma: obj1.do_datuma,
                  kolicina: obj1.kolicina,
                  preuzeto: obj1.ukupno_preuzeto,
                  nije_preuzeto: obj1.ukupno_poslano,
                  ostalo: obj1.ukupno_ostalo
                })
              });

              setDokData(dokData);


            })
      },[props.filterTable]);



  return (
    <Container>
      <BootstrapTable keyField='oib' data={ dokData } columns={ columns } ref={componentRef}/>
      <button type="button" className="bg-gray-500 border border-gray-500 p-2 mb-4" style={{float : 'right'}} onClick={handlePrint}>Ispis</button>
    </Container>
  )
}

export default Tabledata2
