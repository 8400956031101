import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const Navigation = (props) => {

 //console.log(props.userToken.tip);

  return (

    <Navbar bg="dark" variant="dark">
      <Navbar.Brand href="/">PAKOM E-računi</Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link href="/">Početna</Nav.Link>
          <Nav.Link href="/admin" hidden={props.userToken.tip.toString() === '0'}>Admin</Nav.Link>
        </Nav>
      <Navbar.Text>
      Signed in as: {props.userToken.naziv_firme}
    </Navbar.Text>

  </Navbar>
  )

}

export default Navigation
